.logo
{
    position: relative;
    width: 300px;
    height: 300px;
    animation: rotate 2s linear infinite;
    margin: auto;
}

@keyframes rotate 
{
    0%
    {
        transform: rotate(0deg);
    }    
    100%
    {
        transform: rotate(360deg);
    }    

}

.logo circle
{
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 20;
    stroke: fuchsia;
    stroke-linecap: round;
    transform: translate(10px, 10px);
    stroke-dasharray: 880;
    stroke-dashoffset: 880;
    animation: animate 4s linear infinite;
}

@keyframes animate 
{
    0%,100%
    {
        stroke-dashoffset: 880;
    }
    50%
    {
        stroke-dashoffset: 0;
    }
    50.1%
    {
        stroke-dashoffset: 1760;
    }
}